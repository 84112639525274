<template>
  <div>
    <v-dialog v-model="modelObj.menu" max-width="320">
      <template #activator="{ on, attrs }">
        <v-text-field :label="label" hide-details :outlined="outlined" dense readonly
        v-on="on" v-bind="attrs" v-model="modelObj.dateTime" append-icon="mdi-calendar"></v-text-field>
      </template>
      <v-card>
        <v-card-title class="pa-0">
          <v-btn icon small class="mb-16 mr-3" @click="modelObj.menu = false" fixed right>
            <v-icon> mdi-close-circle </v-icon>
          </v-btn>
          <v-tabs v-model="modelObj.activeTab" centered grow background-color="primary" color="white" height="50">
            <v-tabs-slider></v-tabs-slider>
            <v-tab class="font-weight-bold"> {{ $t('date') }} </v-tab>
            <v-tab class="font-weight-bold" :disabled="!modelObj.date"> {{ $t('time') }} </v-tab>
          </v-tabs>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-tabs-items v-model="modelObj.activeTab">
            <v-tab-item>
              <v-card tile flat>
                <v-card-text class="pa-0">
                  <!-- <v-date-picker v-model="modelObj.date" show-adjacent-months no-title @input="$set(modelObj, 'activeTab', 1)" -->
                  <v-date-picker v-model="modelObj.date" show-adjacent-months no-title
                  @change="updateDateTime; modelObj.activeTab += 1" full-width id="custom-date-picker"></v-date-picker>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card tile flat>
                <v-card-text class="pa-0">
                  <v-time-picker v-model="modelObj.time" format="24hr" @click:hour="updateDateTime" @click:minute="updateDateTime; modelObj.menu = false; modelObj.activeTab = 0"
                  full-width id="custom-time-picker"></v-time-picker>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: ['label', 'value', 'outlined', 'enddatetime'],
  data () {
    return {
      modelObj: {
        menu: false,
        activeTab: 0
      }
    }
  },
  watch: {
    value: {
      handler () {
        if (this.value) {
          this.modelObj.date = this.value.split('T')[0]
          this.modelObj.time = this.value.split('T')[1]
          this.modelObj.dateTime = this.$formatter.formatDate(this.modelObj.date, 'YYYY-MM-DD', this.userDetails.dateformat) + ' ' + this.modelObj.time
          this.$forceUpdate()
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    updateDateTime () {
      const defaultTime = this.enddatetime !== undefined ? '23:59:00' : '00:00:00'
      this.modelObj.dateTime = this.modelObj.date + ' ' + (this.modelObj.time || defaultTime)
      this.$emit('input', this.modelObj.date + 'T' + (this.modelObj.time || defaultTime))
      // this.modelObj.activeTab = 0
      this.$emit('updateStartEndDate')
    }
  },
  computed: {
    ...mapGetters(['userDetails'])
  }
}
</script>
<style>
  #custom-date-picker {
    padding-bottom: 5px;
  }
  #custom-date-picker .v-date-picker-header__value {
    font-size: 1rem;
  }
  #custom-time-picker {
    border-radius: 0 !important;
  }
  #custom-time-picker .v-picker__title {
    padding: 1px 5px !important;
  }
  #custom-time-picker .v-time-picker-title * {
    font-size: 2rem !important;
    display: flex !important;
    margin: 0 auto !important;
  }
</style>
